<template>
  <div class="container">

    <memlist-modal
      size="sm"
      ref="edit-form"
      :visible="show_modal" @close="show_modal = false"
      hide-footer
      >

      <memlist-text-input
        id="name"
        name="name"
        :title="$t('ADVANCED_SEARCH.AUDIENCE_NAME')"
        layout="vertical"
        v-model="local_item.name"
      />
      
      <RightModalSaveAndCloseButtons
        class="mt-4"
        :text="$t('COMMON.SAVE')"
        :spin="true"
        @clicked="save"
        @close="show_modal = false"
        ref="saveButton"
      />

    </memlist-modal>

  </div>

</template>


<script>

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


export default {

  name: 'AudienceEditorModal',

  props: ['item'],
  emits: [],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
  },

  watch: {
    item: {
      handler(val) {
        this.local_item = { ...this.item };
      },
      immediate: true
    }
  },

  computed: {
    
    agent_type_options() {

      const base = [
        { text: this.$t('SALES.AGENT.TYPES.AI'), value: 'AI' },
        { text: this.$t('SALES.AGENT.TYPES.HUMAN'), value: 'HUMAN' },
      ];

      return base;
    },

    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId']),

  },

  methods: {

    created(data) {
      this.$refs['saveButton'].stop();
      this.hide();

      this.$emit('created', data);
    },

    async save() {

      if (!this.local_item.id) {
        const res = await axios.post(`/user/audience`, this.local_item);
        if (res.status === 201) {
          this.local_item = res.data;
          this.$emit('created', this.local_item);
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_CREATE_AUDIENCE'));
        }
      }
      else {
        const res = await axios.put(`/user/audience/${this.local_item.id}`, this.local_item);
        if (res.status === 200) {
          this.$emit('updated', this.local_item);
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_UPDATE_AUDIENCE'));
        }
      }

      this.$refs['saveButton'].stop();
      this.hide();
    },

    show() {
      this.show_modal = true;
    },

    hide() {
      this.show_modal = false;
    },

    updated(item) {
      this.hide();
    }
  },

  mounted() {
    
  },

  data() {
    return {
      local_item: {},
      show_modal: false,
      creating: true,
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_table.scss";
@import "@/assets/sass/components/forms/_common_modals.scss";
</style>
