<template>
  <div>

    
    <MemberEditorCompactModal
      ref="member-editor-compact-modal-tab"
      class="mt-8"
      :member_id="member_id"

      @created="member_created"
      @updated="member_updated"
      @deleted="member_deleted"
    />

    <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">
      <!--<AdvancedSearchSaveQueryButton
        class="mb-8"
        :column_names="selected_column_keys"
        :search_options="search_options"
      />-->

      <AdvancedSearchExport
        class="mb-8"
        :column_names="selected_column_keys"
        :search_options="search_options"
      />

    </div>
    
    <b-row>
      <b-col cols="12">

        <Multiselect
          class="ml-2 multiselect-blue"
          v-model="selected_column_keys"
          mode="tags"
          :close-on-select="false"
          :searchable="true"
          :options="options"
        />

      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ count }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="count"
            :per-page="per_page"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>

    <b-table
      id="search-result-table"
      class="mt-3 cursor-pointer"
      sticky-header
      responsive
      striped
      hover
      bordered
      :items="members"
      :fields="shown_table_columns"
      @row-clicked="row_clicked"
      @sort-changed="on_sort_changed"
    >
      <template #cell(type)="data">
        <span>{{ memberTypes[data.item.type] || '' }}</span>
      </template>
      <template #cell(paying)="data">
        <span>{{ data.item.paying === 1 ? $t('COMMON.YES') : $t('COMMON.NO') }}</span>
      </template>
    </b-table>

  </div>
</template>


<style src="@vueform/multiselect/themes/default.css"></style>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import {
  SET_SEARCH_RESULT_PREF
} from '@/core/services/store/common.module';
import store from '@/core/services/store';
import AdvancedSearchExport from './AdvancedSearchExport.vue';
import MemberEditorCompactModal from '@/view/components/member_editor_v2/MemberEditorCompactModal.vue';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'
import AdvancedSearchSaveQueryButton from './AdvancedSearchSaveQueryButton.vue';


export default {
  name: 'AdvancedSearchTable',
  components: {
    Multiselect,
    AdvancedSearchExport,
    MemberEditorCompactModal,
    AdvancedSearchSaveQueryButton,
  },
  mixins: [ toasts ],
  props: {
    fixed_columns: { type: Array, default: null },
    search_options: { type: Object, default: () => ({}) },
    count: { type: Number, default: 0 },
    members: { type: Array, default: [] },
    includeCompanies: { type: Boolean, default: false },
    height: { type: String, default: '800px' }
  },
  emits: ['memberSelected', 'per_page_changed', 'order_by_changed'],
  data() {
    return {
      selected_column_keys: [],

      order_by: {},
      member_id: null,
      current_page: 1,
      per_page: 100,

      memberTypes: {},
      showNumResults: false,
      attributeOptions: [],
    };
  },
  watch: {

    selected_column_keys() {
      store.dispatch(SET_SEARCH_RESULT_PREF, this.selected_column_keys);
    },

    per_page() {
      this.$emit('per_page_changed', this.per_page, this.current_page);
    },

    current_page() {
      this.$emit('per_page_changed', this.per_page, this.current_page);
    },

    members() {
      this.showNumResults = true;
    }
  },
  async mounted() {
    const loader = this.$loading.show();


    this.selected_column_keys = store.getters.searchResultPref;

    this.load_membertypes();
    this.loadRegions();

    await this.load_attribute_groups();

    setTimeout(()=>{
      if (this.selected_column_keys.length === 0) {
        this.selected_column_keys = [
          'public_id',
          'firstname',
          'lastname',
          'address',
          'zipcode',
          'post',
          'email',
          'phone'
        ];
      }

      loader && loader.hide();
    }, 1000);
  },
  computed: {

    shown_table_columns() {
      return this.fieldDef.filter(item => this.selected_column_keys.includes(item.key));
    },

    search_result_style() {
      return {
        height: this.height + 'px',
        position: 'relative',
        overflow: 'auto'
      };

    },

    ...mapGetters(['periods', 'currentPeriodId', 'companies', 'profile', 'searchResultPref']),

    options() {
      return this.fieldDef.map(item => {
        return { value: item.key, label: item.label };
      });
    },


    fieldDef() {

      const fields = [
        { key: 'student_id', label: this.$t('MEMBER.STUDENT_ID'), show: this.profile ? this.profile.student_id === 1 : false, sortable: true },
        { key: 'public_id', label: this.$t('MEMBER.PUBLIC_ID'), show: true, sortable: true },
        { key: 'in_personnr', label: this.$t('MEMBER.PERSONNR'), show: false, sortable: true },
        { key: 'rdt', label: this.$t('MEMBER.RDT'), show: false, sortable: true },
        { key: 'firstname', label: this.$t('MEMBER.FIRSTNAME'), show: true, sortable: true },
        { key: 'lastname', label: this.$t('MEMBER.LASTNAME'), show: true, sortable: true },
        { key: 'co', label: this.$t('MEMBER.CO'), show: true, sortable: true },
        { key: 'cname', label: this.$t('MEMBER.CNAME'), show: false, sortable: true },
        { key: 'address', label: this.$t('MEMBER.ADDRESS'), show: true, sortable: true },
        { key: 'zipcode', label: this.$t('MEMBER.ZIPCODE'), show: true, sortable: true },
        { key: 'post', label: this.$t('MEMBER.POST'), show: true, sortable: true },
        { key: 'company_name', label: this.$t('COMPANY.COMPANY_NAME'), show: true, sortable: true },
        { key: 'cnum', label: this.$t('COMPANY.CNUM'), show: true, sortable: true },
        { key: 'vfdt', label: this.$t('MEMBER.VFDT'), show: true, sortable: true },
        { key: 'vtdt', label: this.$t('MEMBER.VTDT'), show: true, sortable: true },
        { key: 'email_status', label: this.$t('MEMBER.EMAIL_STATUS'), show: true, sortable: true,
          formatter: (_, __, item) => {
            return this.$t('MEMBER.EMAIL_STATUSES.' + item.email_status.toUpperCase());
          }
        },
        {
          key: 'region_code',
          label: this.$t('MEMBER.REGION'),
          show: false,
          sortable: true,
          formatter: (_, __, item) =>
            this.regions && this.regions[item.region_code]
              ? this.regions[item.region_code].region
              : item.region_code
        },
        {
          key: 'commune_code',
          label: this.$t('MEMBER.COMMUNE'),
          show: false,
          sortable: true,
          formatter: (_, __, item) => {
            return this.regions && this.regions[item.region_code]
              ? this.regions[item.region_code].communes[item.commune_code] || item.commune_code
              : item.commune_code;
          }
        },
        { key: 'country', label: this.$t('MEMBER.COUNTRY'), show: true, sortable: true },
        { key: 'email', label: this.$t('MEMBER.EMAIL'), show: true, sortable: true },
        { key: 'phone', label: this.$t('MEMBER.PHONE'), show: true, sortable: true },
        { key: 'fid', label: this.$t('MEMBER.FID'), show: true, sortable: true },
        { key: 'invoice_firstname', label: this.$t('MEMBER.INVOICE_FIRSTNAME'), show: false, sortable: true },
        { key: 'invoice_lastname', label: this.$t('MEMBER.INVOICE_LASTNAME'), show: false, sortable: true },
        { key: 'invoice_address', label: this.$t('MEMBER.INVOICE_ADDRESS'), show: false, sortable: true },
        { key: 'invoice_zipcode', label:this.$t('MEMBER.INVOICE_ZIPCODE'), show: false, sortable: true },
        { key: 'invoice_post', label: this.$t('MEMBER.INVOICE_POST'), show: false, sortable: true },
        { key: 'invoice_email', label: this.$t('MEMBER.INVOICE_EMAIL'), show: false, sortable: true },
        { key: 'invoice_phone', label: this.$t('MEMBER.INVOICE_PHONE'), show: false, sortable: true },
        { key: 'age', label: this.$t('MEMBER.AGE'), show: false, sortable: true },
        { key: 'sex', label: this.$t('MEMBER.SEX'), show: false, sortable: true },
        { key: 'public_sex', label: this.$t('MEMBER.PUBLIC_SEX'), show: false, sortable: true },
        { key: 'ladok', label: this.$t('MEMBER.LADOK'), show: false, sortable: true },
        { key: 'type', label: this.$t('MEMBER.TYPE'), show: false, sortable: true },
        { key: 'hd', label: this.$t('MEMBER.HD'), show: false, sortable: true },
        { key: 'deactivate_at', label: this.$t('MEMBER.DEACTIVATE_AT'), show: false, sortable: true },
        /*{ key: 'paying', label: this.$t('COMMON.PAID'), show: false, sortable: true },*/
        { key: 'avd', label: this.$t('MEMBER.AVD'), show: false, sortable: true },
        { key: 'skv_period_id', label: this.$t('MEMBER.SKV_PERIOD_ID'), show: false, sortable: true,
          formatter: (_, __, item) => {
            return this.periods && item.skv_period_id ? this.periods.find(i => i.id === item.skv_period_id).name : '';
          }
         },
        { key: 'skv_status', label: this.$t('MEMBER.SKV_STATUS'), show: false, sortable: true,
          formatter: (_, __, item) => {
            return item.skv_status ? this.$t('SKV.SKV_STATUSES.'+item.skv_status) : ''
          } 
        },
        
      ]

      // add attributeOptions
      fields.push(...this.attributeOptions);

      return fields;
    }
  },
  methods: {

    on_sort_changed(sortChanged) {

      if (sortChanged.sortBy === '') {
        this.order_by = undefined;
      }
      else {
        this.order_by = {

        };

        this.order_by[sortChanged.sortBy] = sortChanged.sortDesc ? 'desc' : 'asc';
      }

      this.$emit('order_by_changed', this.order_by);
    },

    async load_attribute_groups() {
      try {
        const res = await axios.get(`/property_group/columns`);

        if (res.status === 200) {
          const lookup = {};

          for (const group of res.data) {
            // make sure we dont add duplicates
            if (group.column_name in lookup) {
              continue;
            }

            lookup[group.column_name] = true;

            this.attributeOptions.push({ key: group.column_name, label: group.group_name, show: true, sortable: true, isAttr: true });
          }
        }
      }
      catch (err) {
        console.error(err);
      }

    },

    async load_membertypes() {
      try {
        const res = await axios.get('/membertype')

        if (res.status === 200) {
          this.memberTypes = res.data.reduce((prev, curr) => {
            prev[curr.id] = curr.name;
            return prev;
          }, {});
        }
      }
      catch (err) {
        console.error('load_membertypes', err);
      }

    },
    row_clicked(record, index) {
      if (record.is_member !== undefined && record.is_member !== 1) {
        this.toastr('warning', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_EDIT'));
        return;
      }

      this.member_selected(record.id);

      this.$emit('memberSelected', record);
    },

    member_selected(member_id) {
      this.member_id = member_id;
      this.$refs['member-editor-compact-modal-tab'].show(member_id);
    },

    loadRegions() {
      axios
        .get('https://zip.memlist.se/api/v1/regions_communes')
        .then(res => {
          this.regions = res.data.regions;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_REGIONS'));
        });
    },
    getShowFieldsNames(items) {
      let fields = [];
      items.filter(item => {
        fields.push(item.key);
      });
      return fields;
    },

  }
};
</script>

<style scoped>

.multiselect-blue {
  --ms-tag-bg: #DBEAFE;
  --ms-tag-color: #2563EB;
}

.page-count-select {
  min-width: 100px;
}
.b-table-sticky-header {
  overflow-y: hidden;
  max-height: unset;
}

#search-result-table {
  overflow-y: scroll;
}


.table-wrapper-scroll-y {
  display: block;
}





</style>
