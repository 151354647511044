<template>
  <div ref="page-container" class="page-container">

    <div class="mt-8 mr-2" v-if="local_search_options">

      <b-row>
        <b-col lg="3" sm="12">
          <!-- Email -->
          <b-form-group :label="$t('ADVANCED_SEARCH.EMAIL')">

            <b-form-select v-model="local_search_options.user_variables.email" :options="exists_missing_all_options">
            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          <!-- Mobile -->
          <b-form-group :label="$t('ADVANCED_SEARCH.PHONE')">

            <b-form-select v-model="local_search_options.user_variables.phone" :options="exists_missing_all_options">
            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col lg="3" sm="12">
          <!-- Personnr -->
          <b-form-group :label="$t('ADVANCED_SEARCH.PERSONNR')">

            <b-form-select v-model="local_search_options.user_variables.personnr" :options="exists_missing_all_options">
            </b-form-select>

          </b-form-group>
        </b-col>

        <b-col lg="3" sm="12">
        
          <b-form-group :label="$t('ADVANCED_SEARCH.AUDIENCE')">

            <b-form-select v-model="audience_id" :options="audiences_options">
            </b-form-select>

          </b-form-group>
        </b-col>

      </b-row>


    </div>

    <b-row>

      <b-col lg="12" sm="12">

        <div class="d-flex justify-content-end mb-4 mr-2">

          <RightSaveButton ref="search-button" :text="$t('COMMON.SEARCH')" @clicked="run_search()" />

        </div>
      </b-col>
    </b-row>

    <hr />


  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { is_mobile } from '@/core/services/utils';

import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {
  name: 'AdvancedSearchUserEditor',
  components: {
    RightSaveButton
  },
  props: [ 'member_id', 'expand', 'show_save_search', 'source', 'search_options' ],
  emits: [ 'on_search_result', 'on_search_count_result' ],
  computed: {
    ...mapGetters(
      [
        'currentCompanyId',
        'currentPeriodId',
        'currentUserId',
        'companies',
        'periods',
        'profile',
        'settings',
        'isTHS',
        'sid'
      ]),
    company_options() {
      if (!this.companies) {
        return [];
      }

      return this.companies.map((item) => ({ text: item.name, value: item.id }));
    },
    period_options() {
      if (!this.periods) {
        return [];
      }

      const options = [{ text: this.$t('COMMON.ALL'), value: null }];

      const tmp = this.periods.map((item) => ({ text: item.name, value: item.id }));

      for (const t of tmp) {
        options.push(t);
      }

      return options;
    },

    is_mobile() {
      return is_mobile();
    },

  },
  mixins: [ toasts ],
  watch: {
    source() {
      if (this.source === 'SENDOUT') {
        this.local_search_options.is_sendout = true;
      }
    },
    search_options: {
      deep: true,
      async handler(val) {

        if (this.search_options !== null) {
          this.local_search_options = { ...this.search_options }
        }
        
        this.local_search_options.source = this.source;

        if (this.local_search_options.source === 'SENDOUT') {
          this.local_search_options.is_sendout = true;
        }

        await this.get_audiences();
      },
      immediate: true
    },

  },
  async mounted() {

  },
  methods: {

    async get_audiences() {
      try {
        const res = await axios.get(`/user/audiences`);

        if (res.status === 200) {

          this.audiences_options = res.data.map(item => ({ value: item.id, text: item.name }));
          this.audiences_options.unshift({ value: null, text: this.$t('COMMON.ALL') });

          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_GET'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_GET'));
      }
    },

    async change_per_page(per_page, page) {
      this.local_search_options.limit = per_page;
      this.local_search_options.page = page;

      this.run_search();
    },

    async save_search() {
      try {
        if (!this.local_search_options.query.name || this.local_search_options.query.name === '') {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.NAME_REQUIRED'));
          return;
        }

        const res = await axios.post(`/search_query/${this.currentCompanyId}`, { name: this.local_search_options.query.name, search_options: this.local_search_options });

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('ADVANCED_SEARCH.SAVED'));
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SAVE'))
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SAVE'))
      }
    },

    async run_search_count() {
      try {

        if (this.audience_id) {
          this.local_search_options.user_audiences.audience_ids = [this.audience_id];
        }
        else {
          this.local_search_options.user_audiences.audience_ids = [];
        }

        const res = await axios.post(`/search/pagination/options/count`, { search_options: this.local_search_options });

        if (res.status === 200) {
          this.$emit('on_search_count_result', res.data.count);
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SEARCH'))
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SEARCH'))
      }
    },

    async run_search() {
      try {

        if (this.audience_id) {
          this.local_search_options.user_audiences.audience_ids = [this.audience_id];
        }
        else {
          this.local_search_options.user_audiences.audience_ids = [];
        }
        
        const res = await axios.post(`/search/pagination/options`, { search_options: this.local_search_options });

        this.$refs['search-button'].stop();

        this.run_search_count();

        if (res.status === 200) {
          this.$emit('on_search_result', res.data, this.local_search_options);
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SEARCH'))
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SEARCH'))
      }

      this.$refs['search-button'].stop();
    },

  },
  data() {
    return {

      audience_id: null,

      audiences_options: [],
      member_type_options: [],

      all_fields_shown: false,

      exists_missing_all_options: [
        { value: null, text: this.$t('ADVANCED_SEARCH.ALL') },
        { value: 'EXISTS', text: this.$t('ADVANCED_SEARCH.EXISTS') },
        { value: 'NOT_EXISTS', text: this.$t('ADVANCED_SEARCH.MISSING') }
      ],

      local_search_options: {

        source: 'MEMBER', // MEMBER, SENDOUT, RENEW
        entity_type: 'USER', // MEMBER, USER, PERSON
        is_sendout: false,

        user_audiences: {
          include: 'ANY', // ANY/ALL
          audience_ids: [], // array of ml_audience.id
        },

        // list of values
        properties: {
          include: 'ANY', // ANY/ALL
          prop_ids: [], // array of ids
        },

        memberships: {
          include: 'ANY', // ANY/ALL
          shop_item_ids: [] // array of ids
        },

        events: {
          include: 'ANY', // ANY/ALL
          event_ids: [], // array of ids
        },

        educations: {
          include: 'ANY',
          education_ids: [],
        },

        companies: {
          include: 'ANY',
          company_ids: [], // array of company ids
        },

        page: 1,
        limit: 100,

        order_by: {
          user_id: 'desc'
        },

        query: {
          id: null,
          name: null
        },

        member_variables: {
          // simple values
          skv_period_id: null,
          period_id: null, // int id
          membertype_id: null, // id

          no_mc_from: null, // yyyy-mm-dd
          no_mc_to: null, // yyyy-mm-dd
          exist_mc_from: null, // yyyy-mm-dd
          exist_mc_to: null, // yyyy-mm-dd
          rdt_from: null, // yyyy-mm-dd
          rdt_to: null, // yyyy-mm-dd
          age_from: null, // 0 - 100
          age_to: null, // 0 - 100


          sex: null, // M, F, U, O
          public_sex: null, // M, F, U, O

          email: null, // EXISTS, NOT_EXISTS
          phone: null, // EXISTS, NOT_EXISTS
          address: null, // EXISTS, NOT_EXISTS
          zipcode: null, // EXISTS, NOT_EXISTS
          country: null, // EXISTS, NOT_EXISTS
          shop_item: null, // EXISTS, NOT_EXISTS
          personnr: null, // EXISTS, NOT_EXISTS

          deactivated: null, // 1, 0
          deceased: null, // 1, 0
          hd: null, // 1, 0
          payment_status: null, // 1, 0
        },

        user_variables: {

          email: null, // EXISTS, NOT_EXISTS
          phone: null, // EXISTS, NOT_EXISTS
          personnr: null, // EXISTS, NOT_EXISTS


          created_from: null, // yyyy-mm-dd
          created_to: null, // yyyy-mm-dd

          title_id: null,
          terms: null,
          group_id: null
        },


      },

      membertypes: [],

    }
  },
}

</script>

<style lang="css" scoped>
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: 9px;
  display: block;
}

.hide-fields-button-container {
  height: 54px;
  position: relative;
  width: 216px;
  margin: auto;
  background-color: white;
}


.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding: 4px !important;
}

.row {
  margin-left: 0px !important;
}

.col-form-label {
  margin-top: 6px !important;
}

.page-container {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.fill-rest {
  flex: 1 1 auto;
}


.quick-search-card {
  overflow: scroll;
}

.selected-item-active {
  border-radius: 4px;
  background: #f4fcff;
  color: black;
}

.card-title {
  background-color: #f7f7f7;
  border-bottom: 1px solid #dedede;
  padding-bottom: 15px;

}

.card-title-p {
  margin-bottom: 8px !important;
  margin-top: 7px !important;
}


.top-container {
  display: flex;
}

.content {
  flex-grow: 1;
  margin-top: 0px !important;
  padding-top: 0px !important;
  margin-left: 8px !important;
}



.drawer-header {
  margin-bottom: -13px !important;
}

.drawer {
  border-right: 1px solid #dddddd !important;
}

.fixed-font {
  font-size: 0.95rem !important;
  font-weight: 500;
  line-height: 1rem;
}

:deep .v-application--wrap {
  min-height: 0px !important;
}

.fix_margin {
  height: 38px;
  margin: 0px !important;
  padding: 0px !important;
}
</style>
