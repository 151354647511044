<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h5>&nbsp;</h5>
      <b-dropdown :text="$t('COMMON.EXPORT')" variant="outline-primary">
        <b-dropdown-item @click.prevent="export_excel_clicked($event)">
          <fa-icon :icon="['fas', 'file-excel']" class="mr-2" size="lg" />
          {{ $t('EXPORT.EXCEL') }}
        </b-dropdown-item>
        <b-dropdown-item @click.prevent="export_member_letter_labels_clicked()">
          <fa-icon :icon="['fas', 'file-excel']" class="mr-2" size="lg" />
          {{ $t('EXPORT.LETTER_LABELS') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <b-modal ref="success-job" hide-footer>
      <div class="row">
        <div class="col-12 text-center">
          <i class="fa fa-check-circle" style="font-size: 42px; color: #8dda8d;"></i>
        </div>
      </div>
      <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
        <div class="col-12 text-center">
          <h4 class="event-name" style="text-align: center;">{{$t('PAGES.JOBS.CREATED')}}</h4>
          <br />
          <p>
            {{$t('EXPORT.MEMBER_LETTERS_CREATED')}}
          </p>
        </div>
      </div>
    </b-modal>

  </div>

  
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'advanced-search-export',
  mixins: [ toasts ],
  components: {

  },
  props: ['column_names','search_options'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  data() {
    return {
      
    };
  },
  async mounted() {
    
  },
  watch: {
    
  },
  methods: {


    async get_member_ids_from_search_options() {
      try {
        const res = await axios.post(`/search/pagination/options/ids`, { search_options: this.search_options });

        if (res.status === 200) {
          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SEARCH'))
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SEARCH'))
      }

      return [];
    },
    
    async export_member_letter_labels_clicked() {
      if (this.search_options === null) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.NO_RECORDS_IN_SELECTION'));
        return;
      }

      const loader = this.$loading.show();

      try {
        const member_ids = await this.get_member_ids_from_search_options();

        if (member_ids.length === 0) {
          loader && loader.hide();
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.NO_RECORDS_IN_SELECTION'));
          return;
        }

        const res = await axios.post('/export/letter_labels/member/query', {
          search_options: this.search_options
        });

        if (res.status === 201) {
          loader && loader.hide();
          this.$refs['success-job'].show();

          //downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);

          //this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.EXPORT_SUCCESS'));
        }
        else {
          loader && loader.hide();
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));
        }
      }
      catch (err) {
        loader && loader.hide();
        console.error('export_member_letter_labels', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));
      }
    },
    async export_excel_clicked(e) {
      e.preventDefault();

      if (this.search_options === null) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.NO_RECORDS_IN_SELECTION'));
        return;
      }

      try {
        const localeMessages = this.$i18n.getLocaleMessage(this.$i18n.locale);
        const memberTranslations = localeMessages.MEMBER;

        const res = await axios.post('/search/pagination/options/xlsx', {
          search_options: this.search_options,
          columns: this.column_names,
          company_id: this.currentCompanyId,
          translations: memberTranslations
        });

        if (res.status === 201) {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);

          this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.EXPORT_SUCCESS'));
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));
      }
    }
  }
};
</script>
